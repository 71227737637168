import React from 'react'
import '../styles/footer.css'

export default function Footer() {
  return (
    <div className='footerContainer'>
        <p className='footer'>
        Dominick Albano © 2022
        </p>
    </div>
  )
}

